import {Fragment, useState} from "react";
import {Listbox, Transition} from "@headlessui/react";
import {SelectorIcon} from "@heroicons/react/solid";

import OrganizationUser from "../../types/OrganizationUser";

import ActiveCollaborator from "./ActiveCollaborator";

interface CollaboratorsFieldProps {
    id: string,
    label: string,
    primaryContactId: string,
    organizationUsers: OrganizationUser[],
    subscriptions: string[],
    onChange: (key: string, value: any) => void,
}

// TODO: When primary contact changes, change the list of org users displayed

export default function CollaboratorsField(props: CollaboratorsFieldProps) {
    let subscriptions = props.subscriptions.filter(x => x !== props.primaryContactId)
    let subUsers = subscriptions.map(x => props.organizationUsers.find(y => y.userId === x))
        .filter((item): item is OrganizationUser => !!item)
    const [subscriptionUsers, setSubscriptionUsers] = useState(subUsers)
    const [organizationUsers, setOrganizationUsers] = useState(props.organizationUsers.filter(x => x.userId !== props.primaryContactId))

    const addSubscription = (key: string) => {
        let user = props.organizationUsers.find(x => x.userId === key)
        if (user === undefined || subscriptionUsers.includes(user)) {
            console.log(key)
            return
        }

        let users = subscriptionUsers
        users.push(user)
        setSubscriptionUsers([...users])

        let orgUsers = props.organizationUsers.filter(x => !users.includes(x) && x.userId !== props.primaryContactId)
        setOrganizationUsers([...orgUsers])

        props.onChange(props.id, users.map(x => x.userId))
    }

    const removeSubscription = (key: string) => {
        let users = subscriptionUsers.filter(x => x.userId !== key)
            .filter((item): item is OrganizationUser => !!item)
        setSubscriptionUsers([...users])

        let orgUsers = props.organizationUsers.filter(x => !users.includes(x) && x.userId !== props.primaryContactId)
        setOrganizationUsers([...orgUsers])

        props.onChange(props.id, users.map(x => x.userId))
    }

    let collaborators = subscriptionUsers.map(x => {
        return (<ActiveCollaborator user={x} onClick={removeSubscription}/>)
    })

    let options = organizationUsers.map((organizationUser) => (
        <Listbox.Option key={organizationUser.userId}
                        className='text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4'
                        value={organizationUser.userId}>
            <>
                <div className="flex">
                    <span className='font-normal truncate'>{organizationUser.name}</span>
                    <span className='text-gray-500 ml-2 truncate mt-0.5 text-sm'>{organizationUser.email}</span>
                </div>
            </>
        </Listbox.Option>
    ))

    return (
        <div
            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={props.id}
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {props.label}
            </label>
            <div className='flex flex-col'>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {collaborators}
                </div>
                <div className="mt-2 sm:mt-0 sm:col-span-2">
                    <Listbox value='' onChange={addSubscription}>
                        {({open}) => (
                            <>
                                <div className="mt-1 relative">
                                    <Listbox.Button
                                        className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <div className="flex">
                                            <span className="truncate">Add a collaborator</span>
                                        </div>
                                        <span
                                            className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <Listbox.Options
                                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {options}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
            </div>
        </div>
    )
}