import {useState} from 'react'

import FormError from '../../types/FormError'
import {ExclamationCircleIcon} from "@heroicons/react/solid";

interface TextFieldProps {
    id: string,
    label: string,
    value: string | undefined,
    error?: FormError | undefined,
    onChange: (key: string, value: any) => void
}

export default function TextField(props: TextFieldProps) {
    const [value, setValue] = useState(props.value)
    const changeValue = (v: string) => {
        setValue(v)
        props.onChange(props.id, v)
    }

    return (
        <div
            className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor={props.id}
                   className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                {props.label}
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <input
                    type='text'
                    name={props.id}
                    id={props.id}
                    value={value}
                    onChange={e => changeValue(e.target.value)}
                    className={`${props.error ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 text-blue-900 placeholder-blue-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500'} max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md` }
                />
                {props.error && (
                    <div className="h-5 mt-2 text-sm text-red-600">
                        <ExclamationCircleIcon className="h-5 w-5 mr-1 inline-block" />
                        <span className='align-middle'>
                            {props.error.messageText()}
                        </span>
                    </div>)}
            </div>
        </div>
    )
}