import Asset from '../types/Asset'
import {useState} from 'react'
import UserSession from '../types/UserSession'
import Estimate from '../types/Estimate'
import OtherEstimates from './OtherEstimates'
import UserEstimates from './UserEstimates'
import ConsensusValues from './ConsensusValues'

export default function AssetValuation(props: { asset: Asset }) {
    const userSession: UserSession = JSON.parse(localStorage.getItem('UserSession') || '{}')
    const userKey = userSession.user.userId
    const estimateTypes = userSession.currentOrganization?.estimateTypes || []
    const validTypes = estimateTypes.sort((a, b) => a.position > b.position ? 1 : -1).map((et) => et.name) || ["heavyworth"]
    const singleValue = validTypes.length === 1
    const estimates = props.asset.estimates?.filter((estimate) => validTypes.includes(estimate.estimateTypeName)) || []
    const otherEstimates = estimates.filter((estimate) => estimate.userUuid !== userKey)

    // State
    const [asset, setAsset] = useState(props.asset)
    const [consensusValues, setConsensusValues] = useState(asset?.consensusValues?.filter((value) => validTypes.includes(value.name)) || [])
    const [userEstimates, setUserEstimates] = useState(estimates.filter((estimate) => estimate.userUuid === userKey))
    const [hasChanged, setHasChanged] = useState(false)
    const [showOthers, setShowOthers] = useState(false)

    // Actors
    const valuationNotesChange = (text:string) => {
        setHasChanged(true)
        console.log(`ValuationNotes: ${text}`)
    }

    return (<div className='pb-32'>
        {/*TODO: Save Button - active if something has changed and user can edit*/}
        {/*<SaveValuationChanges hasChanged={hasChanged}/>*/}

        {/*TODO: This User's Estimates - if individual fields active*/}
        <div className='border rounded-lg mb-4'>
            <h2 className='bg-gray-300 p-2 leading-6 font-medium text-gray-900 border-b'>Your Estimate{singleValue ? '' : 's'}</h2>
            <div className='px-4 py-2'>
                <UserEstimates assetKey={asset.key} estimateTypes={estimateTypes} validTypes={validTypes}
                               estimates={userEstimates}/>
            </div>
        </div>

        {/*TODO: Other Estimates - expandable */}
        <div className='border rounded-lg mb-4'>
            <div className='bg-gray-300'>
                <h2 className='p-2 leading-6 font-medium text-gray-900 border-b'>
                    Other Estimates
                    <div className='text-sm float-right' onClick={(e) => setShowOthers(!showOthers)}>
                        {showOthers ? '- Close' : '+ Open'}
                    </div>
                </h2>
            </div>
            <div className={`px-4 py-2 ${showOthers ? 'block' : 'hidden'}`}>
                <OtherEstimates estimateTypes={estimateTypes} validTypes={validTypes} estimates={otherEstimates}/>
                {/*TODO: Median Estimates */}
            </div>
        </div>

        {/*TODO: Final Estimates - editable if user has the rights */}
        <div className='border rounded-lg mb-4'>
            <h2 className='bg-gray-300 p-2 leading-6 font-medium text-gray-900 border-b'>Consensus Value{singleValue ? '' : 's'}</h2>
            <div className='px-4 py-2'>
                <ConsensusValues assetKey={asset.key} estimateTypes={estimateTypes} validTypes={validTypes} values={consensusValues} />
            </div>
        </div>

        {/*TODO: Valuation Notes*/}
        <div className='border rounded-lg mb-4'>
            <h2 className='bg-gray-300 p-2 leading-6 font-medium text-gray-900 border-b'>Valuation Notes</h2>
            <div className='px-4 py-2'>
                {/*<ValuationNotes asset={asset} />*/}
                <textarea id='valuation-notes' value={asset.valuationNotes}
                          className='w-full h-32'
                          onChange={(e) => valuationNotesChange(e.currentTarget.value)}/>
            </div>
        </div>
    </div>)
}