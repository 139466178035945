import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'

import CatalogService from '../../services/CatalogService'

import Catalog from '../../types/Catalog'

import Breadcrumbs from '../../components/Breadcrumbs'
import CatalogForm from '../../components/CatalogForm'

export default function EditCatalog() {
    const params = useParams()
    const catalogId = params.catalogId || ''   // TODO[Error]: Handle null or bad param

    const {isLoading, isError, data, error} = useQuery<Catalog, Error>(
        ['catalog', catalogId],
        async () => {
            return await CatalogService.findById(catalogId)
        },
        {
            staleTime: 5 * 60 * 1000
        }
    )

    const pages = [
        {name: data ? data.name : 'Catalog', href: `/catalogs/${catalogId}`, current: true},
        {name: 'Edit', href: '#', current: true},
    ]

    return (
        <div className='flex flex-grow flex-col'>
            <div className='block'>
                <Breadcrumbs pages={pages}/>
            </div>
            {isLoading && (
                <div className='text-gray-600'>
                    <div className='inline-block animate-ping h-4 w-4 rounded-full bg-gray-600 opacity-75 my-auto'/>
                    <span className='mx-6 my-auto'>Loading catalog details...</span>
                </div>)}
            {isError && (
                <div className='bg-red-500'>
                    Error....{error}
                </div>)}
            {!isLoading && !isError && data && (
                <CatalogForm catalog={data}/>
            )}
        </div>)
}