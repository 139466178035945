import {useState} from 'react'

export interface WorkflowStatusOption {
    value: string,
    name: string,
}

interface WorkflowStatusProps {
    options: WorkflowStatusOption[],
    currentValue: string,
    onChange: (value: string) => void,
}

export default function WorkflowStatus(props: WorkflowStatusProps) {
    let currentStatusIndex = props.options.findIndex(x => x.value === props.currentValue)
    const [selected, setSelected] = useState(currentStatusIndex < 0 ? {value: props.currentValue, name: props.currentValue} : props.options[currentStatusIndex])
    const selectOption = (value: string) => {
        let option = props.options.find(x => x.value === value) || props.options[0]
        setSelected(option)
        props.onChange(value)
    }

    return (
        <div>
            <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue={selected.value}
                onChange={(e) => {selectOption(e.target.value)}}>
                {props.options.map(x => (<option key={x.value} value={x.value}>{x.name}</option>))}
            </select>
        </div>
    )
}