import Breadcrumbs from '../../../components/Breadcrumbs'
import {v4 as uuid} from 'uuid'
import {useState} from 'react'
import Asset from '../../../types/Asset'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import Catalog from '../../../types/Catalog'
import CatalogService from '../../../services/CatalogService'
import AssetForm from '../../../components/AssetForm'

export default function CreateAsset() {
    const params = useParams()
    const catalogKey = params.catalogId || ''   // TODO[Error]: Handle null or bad param

    // Data
    const {isLoading, isError, data, error} = useQuery<Catalog, Error>(
        ['catalog', catalogKey],
        async () => {
            let cat = await CatalogService.findById(catalogKey)
            let newAsset = asset

            newAsset.location = cat.location
            newAsset.catalog = cat
            setAsset(newAsset)

            return cat
        }
    )

    // State
    const assetKey = uuid()
    const initialAsset = new Asset({
        key: assetKey,
        location: '',
    })
    const [asset, setAsset] = useState(initialAsset)

    // Breadcrumbs
    const pages = () => [
        {
            name: data ? data.name : 'Catalog',
            href: `/catalogs/${catalogKey}`,
            current: false
        },
        {
            name: 'New Asset',
            href: `/catalogs/${catalogKey}/assets/new`,
            current: true
        },
    ]

    return (
        <div className='flex flex-grow flex-col'>
            <div className='block'>
                <Breadcrumbs pages={pages()}/>
            </div>
            {isLoading && (<div className='text-gray-600'>
                <div className='inline-block animate-ping h-4 w-4 rounded-full bg-gray-600 opacity-75 my-auto'/>
                <span className='mx-6 my-auto'>Loading supporting data...</span>
            </div>)}
            {data && (
                <div>
                    <main className='h-screen w-screen overflow-hidden'>
                        <AssetForm catalog={data} asset={asset} backToCatalog={true}/>
                    </main>
                </div>
            )}
        </div>
    )
}
