import {CameraIcon} from '@heroicons/react/outline'

import Asset from '../../types/Asset'

export default function ProfileImageCell(props: any) {
    const details: Asset = props.row.original
    const profile = details.images.filter(i => i.imageView === 'AMKT')[0] || details.images[0]
    const url = profile ? profile?.sizes['thumbnail'] : ''

    return (
        <div className='bg-gray-300 relative h-28 w-28 m-1'>
            {url === '' ? <CameraIcon className='h-20 w-20 text-gray-50' /> : <img src={url} />}
            <div className='bg-white absolute right-0 bottom-0 h-6 w-8 text-center z-1'>{details.imageCount}</div>
        </div>
    )
}
