import {TrashIcon} from "@heroicons/react/solid";
import OrganizationUser from "../../types/OrganizationUser";

interface ActiveCollaboratorProps {
    user: OrganizationUser,
    onClick: (key:string) => void
}

export default function ActiveCollaborator(props: ActiveCollaboratorProps) {
    return (
        <div onClick={() => props.onClick(props.user.userId)} className='flex mb-1'>
            <div className='font-normal truncate'>{props.user.name}</div>
            <div className='text-gray-500 ml-2 truncate mt-0.5 text-sm'>{props.user.email}</div>
            <TrashIcon className='h-4 ml-2 mt-1 text-gray-400' />
        </div>
    )
}