import {CameraIcon} from '@heroicons/react/outline'

import Asset from '../types/Asset'

import AssetDisplayElement from './AssetDisplayElement'
import AssetDisplayList from './AssetDisplayList'
import AssetDisplayUpdates from './AssetDisplayUpdates'
import {Link} from "react-router-dom";

export default function AssetDisplay(props: { asset: Asset | undefined }) {
    const asset = props.asset
    if (!asset) {
        return (<div>Missing asset object</div>)
    }

    const profile = asset.images.filter(i => i.imageView === 'AMKT')[0] || asset.images[0]
    const url = profile ? profile?.sizes['thumbnail'] : ''

    const editPath = `/catalogs/${asset.catalog?.uniqueKey()}/assets/${asset.uniqueKey()}/edit`

    const meters = asset.meters.map(meter => <AssetDisplayElement key={meter.units} title={meter.units} value={meter.value.toString()} />)

    return (
        <div className='flex flex-col md:flex-row lg:flex-col lg:pb-64'>
            <div className='pr-2 mr-2 lg:pr-0 md:border-r-2 lg:border-r-0 pb-6'>
                <h1 className='text-lg leading-6 font-medium text-gray-900'>{asset.fullDescription()}</h1>
                <h3 className='leading-6 text-gray-600'>{asset.location}</h3>

                <div className='flex flex-row'>
                    <div className='bg-gray-300 h-40 w-40 my-2 flex-shrink-0 border-2 border-black'>
                        {url === '' ? <CameraIcon className='h-20 w-20 text-gray-50'/> : <img src={url}/>}
                    </div>

                    <ul className='list-none my-2 pl-2 text-xs w-24'>
                        <li className='mb-2'>
                            <Link to={editPath} className='flex p-1 item-center justify-center text-xs rounded text-white bg-orange-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'>Edit</Link>
                        </li>
                        <li className='mb-2'>
                            {/* TODO: Export asset */}
                            <a href='#' className='flex p-1 item-center justify-center text-xs rounded text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'>Export</a>
                        </li>
                        {/*<li className='mb-2'>*/}
                        {/*    /!* TODO: Open asset in new tab *!/*/}
                        {/*    <a href='#' className='flex p-1 item-center justify-center text-xs rounded text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm' target='_blank'>New Tab</a>*/}
                        {/*</li>*/}
                        {/*<li className='mb-2'>*/}
                        {/*    /!* TODO[Feature]: Asset history *!/*/}
                        {/*    <a href='#' className='flex p-1 item-center justify-center text-xs rounded text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm' target='_blank'>History</a>*/}
                        {/*</li>*/}
                        <li className='mb-2'>
                            {/* TODO: Delete asset */}
                            <a href='#' className='flex p-1 item-center justify-center text-xs rounded text-white bg-red-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'>Delete</a>
                        </li>
                    </ul>
                </div>

                <AssetDisplayUpdates asset={asset}/>
            </div>

            <div>
                <dl>
                    {/* TODO[UI]: Display this section differently when there is valuation info */}
                    {(asset.valuationType && <AssetDisplayElement title='Valuation Type' value={asset.valuationType}/>)}
                    {/* TODO: Display consensus info */}
                    {(asset.valuationNotes && <AssetDisplayElement title='Valuation Notes' value={asset.valuationNotes}/>)}
                </dl>
                <dl>
                    {(asset.vin && <AssetDisplayElement title='VIN' value={asset.vin}/>)}
                    {(asset.serial && <AssetDisplayElement title='Serial' value={asset.serial}/>)}
                    {(asset.stickerId && <AssetDisplayElement title='ICN/Sticker' value={asset.stickerId}/>)}
                    {(asset.inventoryTag && <AssetDisplayElement title='Inventory Tag' value={asset.inventoryTag}/>)}
                    {(asset.uniqueId && <AssetDisplayElement title='Unique ID #' value={asset.uniqueId}/>)}
                </dl>
                <dl>
                    {meters}
                </dl>
                <dl>
                    {(asset.features && asset.features.length > 0 && <AssetDisplayList title='Features' values={asset.features}/>)}
                    {(asset.problems && asset.problems.length > 0 && <AssetDisplayList title='Problems' values={asset.problems}/>)}

                    {/* TODO[UI]: Better notes display element that respects formatting and has more space */}
                    {(asset.notes && <AssetDisplayElement title='Notes' value={asset.notes}/>)}
                </dl>
            </div>
        </div>
    )
}