import AssetDisplay from './AssetDisplay'
import {useAssetContext} from '../routes/catalogs/assets/AssetDetail'

// This component wraps the asset display component so that
// it can be reused in the nested asset routes.
export default function DetailsPlaceHolder() {
    const context = useAssetContext()

    return (
        <div>
            <AssetDisplay asset={context.asset} />
        </div>
    )
}