import {useState} from 'react'
import {InformationCircleIcon, LocationMarkerIcon, RefreshIcon, TrashIcon, ZoomInIcon} from '@heroicons/react/solid'

import Image from '../types/Image'

import ImageFooter from './ImageFooter'
import ImageDetails from './ImageDetails'
import {ImageView} from '../types/ImageView'

interface ImageDisplayProps {
    image: Image,
    delete: (image: Image) => {},
    rotate: (image: Image, rotation: number) => {},
    recategorize: (image: Image, newView: ImageView) => {},
}

export default function ImageDisplay(props: ImageDisplayProps) {
    const [image, setImage] = useState(props.image)
    const [loading, setLoading] = useState(true)
    const [detailsOpen, setDetailsOpen] = useState(false)

    if (!(image && image.sizes && (image.sizes.reduced || image.sizes.original))) {
        // TODO[ErrorHandling]: This needs to display a real placeholder image
        return (
            <div className='pb-4 mx-auto'>
                <img src='https://placehold.jp/1600x1200.jpg'/>
            </div>
        )
    }

    const imageLoaded = () => {
        setLoading(false)
    }

    const isLoading = () => {
        return (loading || detailsOpen)
    }

    const verifyDeleteImage = () => {
        console.log("Verify delete image")
        props.delete(image)
    }

    // TODO: Fix local display of rotation
    // TODO: Update image details after rotation finishes
    const rotateImage = () => {
        props.rotate(image, 90)
    }

    const hasLocation = (image && image.longitude && image.longitude && true)
    const imageLocationUrl = `https://maps.google.com/maps?z=12&t=m&q=loc:${image.latitude}+${image.longitude}`

    return (
        <div className='p-4 mx-auto'>
            <div className="relative">
                {/* Image */}
                <img src={image.sizes.reduced || image.sizes.original} onLoad={(e) => imageLoaded()}/>

                {/* Overlay */}
                <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full">

                    {/* Top Right - Actions */}
                    <div
                        className={`absolute top-0 right-0 flex flex-row gap-4 mt-4 mr-4 ${isLoading() ? "hidden" : ""}`}>
                        <div className="inline bg-white shadow-lg p-2 rounded-lg "
                             onClick={(e) => setDetailsOpen(true)}>
                            <InformationCircleIcon className="flex-shrink-0 h-5 w-5 mx-auto" aria-hidden="true" />
                        </div>
                        <a className={`inline bg-white shadow-lg p-2 rounded-lg ${hasLocation ? "" : "hidden"}`}
                           href={imageLocationUrl} target="_blank">
                            <LocationMarkerIcon className="flex-shrink-0 h-5 w-5 mx-auto" aria-hidden="true"/>
                        </a>
                        <a className="inline bg-white shadow-lg p-2 rounded-lg" href={image.sizes.original}
                           target="_blank">
                            <ZoomInIcon className="flex-shrink-0 h-5 w-5 mx-auto" aria-hidden="true"/>
                        </a>
                    </div>

                    {/* Bottom Right - Edits */}
                    <div
                        className={`absolute bottom-0 right-0 flex flex-row gap-4 mb-4 mr-4 ${isLoading() ? "hidden" : ""}`}>
                        <div className="inline bg-white shadow-lg p-2 rounded-lg"
                             onClick={(e) => {
                                 rotateImage()
                             }}>
                            <RefreshIcon className="flex-shrink-0 h-5 w-5 mx-auto" aria-hidden="true"/>
                        </div>
                        <div className="inline bg-white shadow-lg p-2 rounded-lg"
                             onClick={(e) => {
                                 verifyDeleteImage()
                             }}>
                            <TrashIcon className="flex-shrink-0 h-5 w-5 mx-auto" aria-hidden="true"/>
                        </div>
                    </div>
                </div>

                {/* Overlay - Details */}
                <div
                    className={`absolute bg-opacity-70 top-0 bottom-0 left-0 right-0 w-full h-full z-10 bg-gray-600 ${detailsOpen ? "" : "hidden"}`}>
                    <ImageDetails image={image} close={() => setDetailsOpen(false)}/>
                </div>
            </div>

            <div className={`${loading ? "hidden" : ""}`}>
                <ImageFooter image={image} recategorize={props.recategorize}/>
            </div>
        </div>
    )
}