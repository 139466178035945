import OrganizationUser from "../types/OrganizationUser"

interface CatalogContactsProps {
    primaryContactName: string,
    primaryContactId: string,
    subscriptions: string[],
}

export default function CatalogContacts(props: CatalogContactsProps) {
    let currentOrganizationUsers: OrganizationUser[] = JSON.parse(sessionStorage.getItem('CurrentOrganizationUsers') || '[]')
    let collaborators = props.subscriptions.filter(s => s !== props.primaryContactId)
        .map(x => currentOrganizationUsers.find(y => y.userId === x))
        .filter((item): item is OrganizationUser => !!item)
    let names = collaborators.length < 1 ? '' : collaborators.map(u => u.name).join(', ')

    return (
        <div>
            <div className='text-xs text-gray-400'>Collaborators</div>
            <div className='text-sm'>
                <span className='text-base font-medium'>{props.primaryContactName}</span>
                {collaborators.length > 1 && (<span>, {names}</span>)}
            </div>
        </div>
    )
}