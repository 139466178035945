import React from 'react'

import Amplify, {Storage} from 'aws-amplify'
import {withAuthenticator} from 'aws-amplify-react'

import {IntercomProvider} from 'react-use-intercom'

import Authenticated from './Authenticated'
import {CognitoUser} from '@aws-amplify/auth'

// Record configuration values
console.log('USER POOL: ' + process.env.REACT_APP_USER_POOL)
console.log('IDENTITY POOL: ' + process.env.REACT_APP_IDENTITY_POOL)
console.log('REGION: ' + process.env.REACT_APP_REGION)
console.log('WEB CLIENT: ' + process.env.REACT_APP_WEB_CLIENT)
console.log('INGEST POINT: ' + process.env.REACT_APP_INGEST_POINT)
console.log('API ENDPOINT: ' + process.env.REACT_APP_API_ENDPOINT)

/*
 * [x] Setup AWS session
 * [x] Check for authentication status
 * [x] If not authenticated -> Login Flow
 * [x] If authenticated:
 *     [x] Store User data
 *     [x] Route -> Catalog List or Redirect Path
 */

// Configures Amplify setup from build environment
Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL || 'us-east-1:e839f475-0266-4f7c-ba54-c6fb378169c7',
        region: process.env.REACT_APP_REGION || 'us-east-1',
        userPoolId: process.env.REACT_APP_USER_POOL || 'us-east-1_azTJlZeRb',
        userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT || '7onkvofvfuirh2ak2t86hdv5cb',
        authenticationFlowType: process.env.REACT_APP_AUTH_TYPE || 'USER_SRP_AUTH',
    },
    Storage: {
        bucket: process.env.REACT_APP_INGEST_POINT || 'prd-heavyworth-ingestion',
        region: process.env.REACT_APP_REGION || 'us-east-1',
        customPrefix: {
            public: '',
            protected: '',
            private: ''
        }
    },
    API: {
        endpoints: [
            {
                name: 'api',
                endpoint: process.env.REACT_APP_API_ENDPOINT || 'https://api.heavyworth.com/v1'
            },
        ]
    }
})
Storage.configure({level: 'private'})

const INTERCOM_APP_ID = 'gkkugy4w'

interface AppProps {
    authData: CognitoUser
}

function App(props:AppProps) {
    return (
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
            <Authenticated authData={props.authData}/>
        </IntercomProvider>
    )
}

// Wrapping this with the Amazon Cognito authenticator.
// Only authenticated users will see the app flow.
// Unauthenticated users will be redirected into the
// standard Amplify authentication flow.
// TODO[Later]: Customize authentication flow UI
// TODO[Later]: Add email authentication flow
export default withAuthenticator(App)
