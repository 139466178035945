import Image from '../types/Image'
import {ImageView} from '../types/ImageView'
import ImageViewSelect from './ImageViewSelect'

interface ImageFooterProps {
    image: Image,
    recategorize: (image:Image, newView:ImageView) => void,
}

export default function ImageFooter(props: ImageFooterProps) {
    const image = props.image

    if (!image) {
        return (<div></div>)
    }

    const changeImageView = (newView:ImageView) => {
        image.imageView = newView
        props.recategorize(image, newView)
    }

    return (
        <div className='lg:text-xs text-gray-600'>
            <div className='hidden lg:inline-block float-right'>
                Image Key: {image.imageId}
            </div>
            <div className='inline-block text-lg'>
                <ImageViewSelect currentValue={image.imageView} onChange={changeImageView}/>
            </div>
        </div>
    )
}