import {FormEvent, useState} from 'react'
import {useQuery} from 'react-query'
import {useNavigate} from 'react-router-dom'

import OrganizationUsersService from '../services/OrganizationUsersService'
import CatalogService from '../services/CatalogService'

import OrganizationUser from '../types/OrganizationUser'
import UserSession from '../types/UserSession'
import LocalUserInfo from '../types/LocalUserInfo'
import Organization from '../types/Organization'
import Catalog from '../types/Catalog'
import FormError from '../types/FormError'

import TextField from './forms/TextField'
import EmailField from './forms/EmailField'
import SectionHeader from './forms/SectionHeader'
import FormSection from './forms/FormSection'
import FieldGroup from './forms/FieldGroup'
import TextAreaField from './forms/TextAreaField'
import CancelButton from './forms/CancelButton'
import SubmitButton from './forms/SubmitButton'
import LabelSelectionField, {LabelSelectionOption} from './forms/LabelSelectionField'
import PrimaryContactField from './forms/PrimaryContactField'
import CollaboratorsField from './forms/CollaboratorsField'

export default function CatalogForm(props: { catalog: Catalog }) {
    let userSession: UserSession = JSON.parse(localStorage.getItem('UserSession') || '{}')
    let userInfo: LocalUserInfo = JSON.parse(localStorage.getItem('UserInfo') || '{}')
    let currentOrganization: Organization = userSession.currentOrganization || {}
    let workflowConfig = currentOrganization.workflowConfig || {}
    let reasons: LabelSelectionOption[] = workflowConfig.valuationReasons?.split('|').map((value, index) => {
        return {index: index, label: value, value: value}
    }) || []

    // Data
    const {isLoading, data} = useQuery<OrganizationUser[], Error>(
        'organizationUsers',
        async () => {
            return await OrganizationUsersService.findAll()
        },
        {
            staleTime: 5 * 60 * 1000
        }
    )

    // Defaults
    const defaultValuationReasons: LabelSelectionOption[] = [
        {index: 0, label: 'Bankruptcy', value: 'Bankruptcy',},
        {index: 1, label: 'Estate Planning', value: 'Estate Planning',},
        {index: 2, label: 'Financing', value: 'Financing',},
        {index: 3, label: 'Inspection', value: 'Inspection',},
        {index: 4, label: 'Insurance', value: 'Insurance',},
        {index: 5, label: 'Liquidation', value: 'Liquidation',},
        {index: 6, label: 'Litigation', value: 'Litigation',},
        {index: 7, label: 'Loss of Life', value: 'Loss of Life',},
        {index: 8, label: 'Retirement', value: 'Retirement',},
        {index: 9, label: 'Trade In', value: 'Trade In',},
    ]
    const valuationReasons = (reasons.length > 0) ? reasons : defaultValuationReasons

    // Handling
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState(new Map<string, FormError>())
    const handleCancel = () => {
        navigate('/catalogs')
    }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        console.log(catalog)
        let errors = catalog.valid()
        setFormErrors(errors)
        if (errors.size < 1) {
            // TODO: Handle errors
            saveCatalog().then()
        } else {
            console.log(errors)
        }
        event.preventDefault()
    }
    const saveCatalog = async () => {
        await CatalogService.saveCatalog(catalog).then((updatedCatalog) => {
            console.log(updatedCatalog)
            navigate(`/catalogs/${catalog.uniqueKey()}`)
        })
    }

    // State
    const [catalog, setCatalog] = useState(props.catalog)
    const catalogChange = (key: string, value: any) => {
        let values: { [x: string]: any } = {}
        values[key] = value
        let newCatalog = Object.assign(catalog, values)
        setCatalog(newCatalog)

        let errors = catalog.valid()
        setFormErrors(errors)
    }
    const primaryContactChange = (name: string, key: string) => {
        console.log(`name: ${name} key: ${key}`)
        let newCatalog = Object.assign(catalog, {primaryContactName: name, primaryContactId: key})
        setCatalog(newCatalog)
    }

    return (
        <main className='h-screen w-screen overflow-hidden'>
            {isLoading && (<div className='text-gray-600'>
                <div className='inline-block animate-ping h-4 w-4 rounded-full bg-gray-600 opacity-75 my-auto'/>
                <span className='mx-6 my-auto'>Loading supporting data...</span>
            </div>)}
            {data && (
                <form className='w-full h-full overflow-auto pb-64 space-y-8 divide-y divide-gray-200 p-4'
                      onSubmit={handleSubmit}>
                    <FormSection>
                        <SectionHeader title='Catalog Details' description=''/>
                        <FieldGroup>
                            <TextField id='name'
                                       label='Asset Owner'
                                       value={catalog.name}
                                       error={formErrors.get('name')}
                                       onChange={catalogChange}/>
                            <EmailField id='email'
                                        label='Owner Email'
                                        value={catalog.email}
                                        onChange={catalogChange}/>
                            <TextField id='location'
                                       label='Location'
                                       value={catalog.location}
                                       error={formErrors.get('location')}
                                       onChange={catalogChange}/>
                        </FieldGroup>
                    </FormSection>

                    <FormSection>
                        <SectionHeader title='Catalog Management' description=''/>
                        <FieldGroup>
                            <PrimaryContactField id='primaryContact'
                                                 label='Primary Contact'
                                                 primaryContactName={catalog.primaryContactName}
                                                 primaryContactId={catalog.primaryContactId}
                                                 organizationUsers={data}
                                                 currentUser={{
                                                     name: userInfo.name,
                                                     email: userInfo.email,
                                                     userId: userInfo.key
                                                 }}
                                                 onChange={primaryContactChange}/>
                            <CollaboratorsField id='subscriptions'
                                                label='Collaborators'
                                                primaryContactId={catalog.primaryContactId}
                                                organizationUsers={data}
                                                subscriptions={catalog.subscriptions}
                                                onChange={catalogChange}/>
                        </FieldGroup>
                    </FormSection>

                    <FormSection>
                        <SectionHeader title='Additional Details' description=''/>
                        <FieldGroup>
                            <LabelSelectionField id='valuationReasons'
                                                 label='Valuation Reasons'
                                                 options={valuationReasons}
                                                 values={catalog.valuationReasons}
                                                 onChange={catalogChange}/>
                            <TextAreaField id='notes'
                                           label='Notes'
                                           value={catalog.notes}
                                           onChange={catalogChange}/>
                        </FieldGroup>
                    </FormSection>

                    <div className='pt-5 pr-20'>
                        <div className='flex justify-end mb-2'>
                            {formErrors && formErrors.size > 0 && (
                                <div className='text-red-600 text-sm'>
                                    Input errors have been found.
                                </div>)}
                        </div>
                        <div className='flex justify-end'>
                            <CancelButton title='Cancel' onCancel={handleCancel}/>
                            <SubmitButton title='Save' />
                        </div>
                    </div>
                </form>
            )}
        </main>)
}