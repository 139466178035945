import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from "react-router-dom";
import './index.css';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';

// Setup Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
Sentry.init({
   dsn: "https://d6a63393aa534b208a13d885d805127e@o396255.ingest.sentry.io/5279172",
   environment: process.env.REACT_APP_ENVIRONMENT,
   release: "web2@" + process.env.npm_package_version,
   integrations: [new Integrations.BrowserTracing()],
   tracesSampleRate: 1.0
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
