export default class FormError {
    field: string
    errorType: string
    message?: string

    constructor(field: string, errorType: string, message?:string) {
        this.field = field
        this.errorType = errorType
        this.message = message
    }

    messageText():string {
        if (this.message) {
            return this.message
        }

        switch (this.errorType) {
            case 'required': return 'This field is required.'
        }

        return 'An error has occurred.'
    }
}