import AssetForm from './AssetForm'
import {useAssetContext} from '../routes/catalogs/assets/AssetDetail'

// This component wraps the asset form component so that
// it can be reused in the nested asset routes.
export default function EditPlaceHolder() {
    const context = useAssetContext()

    return (
        <AssetForm catalog={context.catalog} asset={context.asset} backToCatalog={false} />
    )
}
