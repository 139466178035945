import Asset from './Asset'
import FormError from "./FormError";

export interface CatalogOptions {
    key: string
    workflowStatus: string

    name: string
    email?: string
    location: string

    primaryContactName: string
    primaryContactId: string

    valuationReasons: string[]
    subpackages: string[]
    subscriptions: string[]

    consensusValue?: number
    consensusValues?: {name: string, value: number}[]

    created?: string
    updatedBy?: string
    updated?: string

    itemCount?: number
    imageCount?: number

    notes?: string
}

export default class Catalog {
    key: string
    workflowStatus: string

    name: string
    email?: string
    location: string

    primaryContactName: string
    primaryContactId: string

    valuationReasons: string[]
    valuationNotes?: string
    subpackages: string[]
    subscriptions: string[]

    consensusValue?: number
    consensusValues?: {name: string, value: number}[]

    created?: string
    updatedBy?: string
    updated?: string

    itemCount?: number
    imageCount?: number

    notes?: string

    assets: Asset[]

    constructor(options: CatalogOptions) {
        this.key = options.key
        this.workflowStatus = options.workflowStatus

        this.name = options.name
        this.email = options.email
        this.location = options.location

        this.primaryContactName = options.primaryContactName
        this.primaryContactId = options.primaryContactId

        this.valuationReasons = options.valuationReasons || []
        this.subpackages = options.subpackages || []
        this.subscriptions = options.subscriptions || []

        this.consensusValues = options.consensusValues
        this.consensusValue = options.consensusValue

        this.created = options.created
        this.updated = options.updated
        this.updatedBy = options.updatedBy

        this.itemCount = options.itemCount
        this.imageCount = options.imageCount

        this.notes = options.notes

        this.assets = []
    }

    uniqueKey() {
        return this.key
    }

    valid() {
        let errors:Map<string, FormError> = new Map()

        if (!this.name || this.name.trim().length < 1) {
            errors.set('name', new FormError('name', 'required'))
        }
        if (!this.location || this.location.trim().length < 1) {
            errors.set('location', new FormError('location', 'required'))
        }

        return errors
    }

    uri() {
        return `/evaluations/${this.key}`
    }
}