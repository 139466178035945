import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {Auth} from '@aws-amplify/auth'

import { camelizeKeys, decamelizeKeys } from 'humps'

import CatalogSummary from '../responses/CatalogSummary'
import CatalogDetails from '../responses/CatalogDetails'
import Evaluation from '../responses/Evaluation'

import CurrentOrganization from '../types/CurrentOrganization'
import Catalog from '../types/Catalog'
import Asset from '../types/Asset'
import ItemDetail from '../responses/ItemDetail'

const currentOrganization: CurrentOrganization = JSON.parse(sessionStorage.getItem('CurrentOrganization') || '{}')
const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        'Content-type': 'application/json',
    },
})
api.interceptors.response.use((response: AxiosResponse) => {
    if (response.data) {
        response.data = camelizeKeys(response.data)
    }
    return response
})
api.interceptors.request.use((request: AxiosRequestConfig) => {
    if (request.data) {
        request.data = decamelizeKeys(request.data)
    }
    return request
})

function buildCatalog(evaluation:Evaluation):Catalog {
    return new Catalog({
        ...evaluation,
        key: evaluation.evalId,
        name: evaluation.ownerName,
        email: evaluation.owner,
        primaryContactName: evaluation.primaryContact,
    })
}

function buildAsset(details: ItemDetail):Asset {
    let item = details.item
    let asset = new Asset({ ...details.item,
        key: details.itemId,
        heavyWorthId: details.heavyworthId,
        images: details.imageData,
        imageCount: details.imageCount,
        attachments: details.attachmentData,
        attachmentCount: details.attachmentCount,
        consensusValues: details.consensusValues,
        consensus: details.consensus,
        estimates: details.estimates,
        meanEstimates: details.meanEstimates,
        meanEstimate: details.meanEstimate,
        links: details.links,
    })
    return asset
}

function buildFullCatalog(details:CatalogDetails):Catalog {
    let catalog = buildCatalog(details.evaluation)

    catalog.assets = details.items.map(item => buildAsset(item))

    return catalog
}

function evaluationFromCatalog(catalog:Catalog):Evaluation {
   return {
       ...catalog,
       evalId: catalog.uniqueKey(),
       primaryContact: catalog.primaryContactName,
       ownerName: catalog.name,
       owner: catalog.email,
       valuationNotes: catalog.valuationNotes,
       uri: catalog.uri(),
   }
}

async function getAuthToken() {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
}

const findAll = async () => {
    const response = await api.get<CatalogSummary[]>('/v1/evaluations/all/open', {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return response.data.map((summary:CatalogSummary) => {return buildCatalog(summary.evaluation)})
}

const findById = async (id: string) => {
    const response = await api.get<CatalogDetails>(`/v1/evaluations/${id}`, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return buildFullCatalog(response.data)
}

const saveCatalog = async (catalog: Catalog) => {
    let evaluation = evaluationFromCatalog(catalog)
    const response = await api.put<Evaluation>(`/v1/evaluations/${catalog.uniqueKey()}`, evaluation, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return buildCatalog(response.data)
}

const changeState = async (catalog: Catalog) => {
    const response = await api.put(`/v1/evaluations/${catalog.uniqueKey()}/status/${catalog.workflowStatus}`, {}, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return response.data
}

const CatalogService = {
    findAll,
    findById,
    saveCatalog,
    changeState,
}

export default CatalogService