import Asset from '../types/Asset'

export default function AssetDisplayUpdates(props: { asset: Asset }) {
    return (
        <div className='text-xs text-gray-600'>
            <div className='pb-2'>
                {props.asset.created && (
                    <p>Created {props.asset.created.toLocaleString()} by {props.asset.createdBy}</p>
                )}
                {props.asset.updated && (
                    <p>Updated {props.asset.updated.toLocaleString()} by {props.asset.updatedBy}</p>
                )}
            </div>
            <div>
                {props.asset.uniqueKey() && (
                    <p>Asset Key: {props.asset.uniqueKey()}</p>
                )}
                {props.asset.heavyWorthId && (
                    <p>HeavyWorth ID: {props.asset.heavyWorthId}</p>
                )}
            </div>
        </div>)
}