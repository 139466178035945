import {Fragment, useState} from 'react'

import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid'

import OrganizationUser from '../../types/OrganizationUser'

interface PrimaryContactFieldProps {
    id: string,
    label: string,
    primaryContactName: string,
    primaryContactId: string,
    organizationUsers: OrganizationUser[],
    currentUser: OrganizationUser,
    onChange: (name: string, key: string) => void
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function PrimaryContactField(props: PrimaryContactFieldProps) {
    let currentUserIndex = props.organizationUsers.findIndex(x => x.userId === props.currentUser.userId)
    let userIndex = props.organizationUsers.findIndex(x => x.userId === props.primaryContactId)
    const [organizationUsers] = useState(currentUserIndex < 0 ? props.organizationUsers.concat([props.currentUser]) : props.organizationUsers)
    const [selected, setSelected] = useState(userIndex < 0 ? props.currentUser : props.organizationUsers[userIndex])
    const selectUser = (userKey: string) => {
        let organizationUser = organizationUsers.find(x => x.userId === userKey) || organizationUsers[0]
        setSelected(organizationUser)
        props.onChange(organizationUser.name, organizationUser.userId)
    }

    return (
        <div
            className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor={props.id}
                   className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                {props.label}
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <Listbox value={selected.userId} onChange={selectUser}>
                    {({open}) => (
                        <>
                            <div className='mt-1 relative'>
                                <Listbox.Button
                                    className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm'>
                                    <div className='flex'>
                                        <span className='truncate'>{selected.name}</span>
                                    </div>
                                    <span
                                        className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true'/>
              </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave='transition ease-in duration-100'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'
                                >
                                    <Listbox.Options
                                        className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                        {organizationUsers.map((organizationUser) => (
                                            <Listbox.Option key={organizationUser.userId}
                                                            className={({active}) =>
                                                                classNames(
                                                                    active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                    'cursor-default select-none relative py-2 pl-8 pr-4'
                                                                )
                                                            }
                                                            value={organizationUser.userId}>
                                                {({selected, active}) => (
                                                    <>
                                                        <div className='flex'>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {organizationUser.name}
                          </span>
                                                            <span
                                                                className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate mt-0.5 text-sm')}>
                            {organizationUser.email}
                          </span>
                                                        </div>
                                                        {selected ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? 'text-white' : 'text-blue-600',
                                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                                )}
                                                            ><CheckIcon className='h-5 w-5' aria-hidden='true'/></span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            </div>
        </div>
    )
}