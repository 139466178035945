export default function DateCell(props: any) {
    const dateValue: Date = new Date(props.cell.value)

    const dateString = dateValue.toLocaleDateString()
    const timestring = dateValue.toLocaleTimeString()

    return (
        <div className="mx-2">
            <div>{dateString}</div>
            <div>{timestring}</div>
        </div>)
}