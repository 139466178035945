import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {Auth} from '@aws-amplify/auth'

import {camelizeKeys, decamelizeKeys} from 'humps'

import CatalogDetails from '../responses/CatalogDetails'
import AssetDetails from '../responses/AssetDetails'

import CurrentOrganization from '../types/CurrentOrganization'
import Asset from '../types/Asset'
import Catalog from '../types/Catalog'
import Item from '../responses/Item'

const currentOrganization: CurrentOrganization = JSON.parse(sessionStorage.getItem('CurrentOrganization') || '{}')
const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        'Content-type': 'application/json',
    },
})
api.interceptors.response.use((response: AxiosResponse) => {
    if (response.data) {
        response.data = camelizeKeys(response.data)
    }
    return response
})
api.interceptors.request.use((request: AxiosRequestConfig) => {
    if (request.data) {
        request.data = decamelizeKeys(request.data)
    }
    return request
})

async function getAuthToken() {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
}

function buildAsset(details: AssetDetails): Asset {
    let asset = new Asset({
        ...details.item,
        key: details.item.itemId,
        heavyWorthId: details.item.heavyworthId,
        inventoryTag: details.item.personalId,
        versionNumber: details.item.version,
        assetType: details.item.description,
    })
    if (details.evaluations && details.evaluations[0]) {
        const evaluation = details.evaluations[0]
        asset.catalog = new Catalog({
            ...evaluation,
            key: evaluation.evalId,
            name: evaluation.ownerName,
            email: evaluation.owner,
            primaryContactName: evaluation.primaryContact,
        })
    }
    asset.images = details.imageData
    asset.imageCount = details.imageCount
    asset.attachments = details.attachmentData
    asset.attachmentCount = details.attachmentCount
    asset.links = details.links

    asset.estimates = details.estimates
    asset.meanEstimate = details.meanEstimate
    asset.valuationNotes = details.notes

    asset.consensus = details.consensus
    asset.consensusValues = details.consensusValues
    if (!asset.created && details.added) {
        asset.created = new Date(details.added)
    }

    return asset
}

function itemFromAsset(asset: Asset): Item {
    let item: Item = {
        itemId: asset.uniqueKey(),
        initialEval: asset.catalog?.uniqueKey(),
        year: asset.year,
        make: asset.make,
        model: asset.model,
        description: asset.assetType,
        location: asset.location,
        serial: asset.serial,
        vin: asset.vin,
        stickerId: asset.stickerId,
        uniqueId: asset.uniqueId,
        personalId: asset.inventoryTag,
        meters: asset.meters,
        features: asset.features,
        problems: asset.problems,
        notes: asset.notes,
        valuationType: asset.valuationType,
        version: asset.versionNumber || 1,
    }

    return item
}

const findAllForCatalog = async (id: string) => {
    const response = await api.get<CatalogDetails>(`/v1/evaluations/${id}`, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return response.data.items.map(details => new Asset({
        ...details.item,
        key: details.key,
        heavyWorthId: details.heavyworthId,
        images: details.imageData,
        imageCount: details.imageCount,
        attachments: details.attachmentData,
        attachmentCount: details.attachmentCount,
        estimates: details.estimates,
        meanEstimates: details.meanEstimates,
        meanEstimate: details.meanEstimate,
        links: details.links,
    }))
}

const findById = async (id: string) => {
    const response = await api.get<AssetDetails>(`/v1/items/${id}`, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return buildAsset(response.data)
}

const saveAsset = async (asset: Asset) => {
    let item = itemFromAsset(asset)
    const response = await api.put<AssetDetails>(`/v1/items/${asset.uniqueKey()}`, item, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return buildAsset(response.data)
}

const AssetService = {
    findAllForCatalog,
    findById,
    saveAsset,
}

export default AssetService