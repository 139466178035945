import Image from './Image'
import AssetLink from './AssetLink'
import Attachment from './Attachment'
import Catalog from './Catalog'
import Estimate from './Estimate'
import FormError from './FormError'

export interface AssetOptions {
    key: string
    heavyWorthId?: string
    versionNumber?: number

    valuationType?: string

    year?: string
    make?: string
    model?: string
    assetType?: string
    location: string

    serial?: string
    vin?: string
    stickerId?:string
    inventoryTag?:string
    uniqueId?:string

    meters?: {units: string, value: number}[]
    features?: string[]
    problems?: string[]
    notes?: string

    imageCount?: number
    images?: Image[]

    attachmentCount?: number
    attachments?: Attachment[]

    links?: AssetLink[]

    captureEstimate?: number
    consensus?: number
    consensusValues?: {name: string, value: number}[]
    meanEstimate?: number
    meanEstimates?: {name: string, value: number}[]
    estimates?: Estimate[]
    valuationNotes?: string

    updated?: string
    updatedBy?: string
    created?: string
    createdBy?: string
}

export default class Asset {
    key: string
    heavyWorthId?: string
    versionNumber?: number

    valuationType?: string

    year?: string
    make?: string
    model?: string
    assetType?: string
    location: string

    serial?: string
    vin?: string
    stickerId?: string
    inventoryTag?: string
    uniqueId?: string

    meters: { units: string, value: number }[]
    features?: string[]
    problems?: string[]
    notes?: string

    imageCount: number
    images: Image[]

    attachmentCount: number
    attachments: Attachment[]

    links: AssetLink[]

    captureEstimate?: number
    consensus?: number
    consensusValues?: { name: string, value: number }[]
    meanEstimate?: number
    meanEstimates?: { name: string, value: number }[]
    estimates?: Estimate[]
    valuationNotes?: string

    updated?: Date
    updatedBy?: string
    created?: Date
    createdBy?: string

    catalog?: Catalog

    constructor(options: AssetOptions) {
        this.key = options.key
        this.heavyWorthId = options.heavyWorthId
        this.valuationType = options.valuationType
        this.year = options.year
        this.make = options.make
        this.model = options.model
        this.assetType = options.assetType
        this.location = options.location
        this.serial = options.serial
        this.vin = options.vin
        this.stickerId = options.stickerId
        this.inventoryTag = options.inventoryTag
        this.uniqueId = options.uniqueId
        this.meters = options.meters || []
        this.features = options.features
        this.problems = options.problems
        this.notes = options.notes
        this.imageCount = options.imageCount || 0
        this.images = options.images || []
        this.attachmentCount = options.attachmentCount || 0
        this.attachments = options.attachments || []
        this.links = options.links || []
        this.consensus = options.consensus
        this.captureEstimate = options.captureEstimate
        this.consensusValues = options.consensusValues
        this.meanEstimate = options.meanEstimate
        this.meanEstimates = options.meanEstimates
        this.valuationNotes = options.valuationNotes
        if (options.updated) {
            this.updated = new Date(options.updated)
        }
        this.updatedBy = options.updatedBy
        if (options.created) {
            this.created = new Date(options.created)
        }
        this.createdBy = options.createdBy
        this.estimates = options.estimates
        this.versionNumber = options.versionNumber || 1
    }

    uniqueKey() {
        return this.key
    }

    uri() {
        return `/items/${this.uniqueKey()}`
    }

    breadcrumbDescription(): string {
        const desc = this.fullDescription()

        if (desc.trim().length < 1) {
            return `Asset ${this.uniqueKey()}`
        } else {
            return desc
        }
    }

    fullDescription(): string {
        let strings: string[] = []

        if (this.year) {
            strings.push(this.year)
        }
        if (this.make) {
            strings.push(this.make)
        }
        if (this.model) {
            strings.push(this.model)
        }
        if (this.assetType) {
            strings.push(this.assetType)
        }

        return strings.join(' ')
    }

    valid() {
        let errors: Map<string, FormError> = new Map()

        return errors
    }
}