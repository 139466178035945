import {useState} from 'react'
import {v4 as uuid} from 'uuid'

import Catalog from '../../types/Catalog'
import UserSession from '../../types/UserSession'
import Organization from '../../types/Organization'
import LocalUserInfo from '../../types/LocalUserInfo'

import Breadcrumbs from '../../components/Breadcrumbs'
import CatalogForm from '../../components/CatalogForm'

export default function CreateCatalog() {
    let userSession: UserSession = JSON.parse(localStorage.getItem('UserSession') || '{}')
    let userInfo: LocalUserInfo = JSON.parse(localStorage.getItem('UserInfo') || '{}')
    let currentOrganization: Organization = userSession.currentOrganization || {}
    let workflowConfig = currentOrganization.workflowConfig || {}

    // State
    const catalogKey = uuid()
    const initialCatalog = new Catalog({
        key: catalogKey,
        name: '',
        email: '',
        subscriptions: [userInfo.key],
        location: '',
        primaryContactName: userInfo.name,
        primaryContactId: userInfo.key,
        workflowStatus: 'capture_in_progress',
        subpackages: [],
        valuationReasons: [workflowConfig.defaultValuationReason || 'Financing'],
    })
    const [catalog] = useState(initialCatalog)

    // Breadcrumbs
    const pages = [
        {name: 'New Catalog', href: `/catalogs/new`, current: true},
    ]

    return (
        <div className='flex flex-grow flex-col'>
            <div className='block'>
                <Breadcrumbs pages={pages}/>
            </div>
            <CatalogForm catalog={catalog}/>
        </div>
    )
}