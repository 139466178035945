import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {Auth} from '@aws-amplify/auth'

import {camelizeKeys, decamelizeKeys} from 'humps'

import CurrentOrganization from '../types/CurrentOrganization'
import {ImageView} from '../types/ImageView'
import Image from '../types/Image'

const currentOrganization: CurrentOrganization = JSON.parse(sessionStorage.getItem('CurrentOrganization') || '{}')
const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        'Content-type': 'application/json',
    },
})
api.interceptors.response.use((response: AxiosResponse) => {
    if (response.data) {
        response.data = camelizeKeys(response.data)
    }
    return response
})
api.interceptors.request.use((request: AxiosRequestConfig) => {
    if (request.data) {
        request.data = decamelizeKeys(request.data)
    }
    return request
})

async function getAuthToken() {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
}

const deleteImage = async (assetKey: string, imageKey: string) => {
    const response = await api.delete(`/v1/items/${assetKey}/images/${imageKey}`, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return response
}

const rotateImage = async (imageKey: string, rotation: number) => {
    const response = await api.put<Image>(`/v1/images/${imageKey}/rotate/${rotation}`, {}, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return response.data
}

const setImageView = async (assetKey: string, imageKey:string, imageView: ImageView) => {
    const changes = {image_view: imageView}
    const response = await api.patch(`/v1/items/${assetKey}/images/${imageKey}`, changes, {
        headers: {
            'HW-Organization': currentOrganization.key,
            'Authorization': await getAuthToken(),
        }
    })

    return response
}

const ImageService = {
    deleteImage,
    rotateImage,
    setImageView,
}

export default ImageService