import {Column, useSortBy, useTable} from 'react-table'

import AssetTableRow from './AssetTableRow'
import Asset from '../types/Asset'
import {ArrowSmDownIcon, ArrowSmUpIcon} from '@heroicons/react/solid'

interface AssetTableProps {
    columns: Column<Asset>[],
    data: Asset[],
    catalogId: string
}

export default function AssetTable(props: AssetTableProps) {
    const tableInstance = useTable({columns: props.columns, data: props.data}, useSortBy)
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,} = tableInstance

    return (
        <div className='overflow-x-auto overflow-y-auto max-w-screen max-h-screen pb-72'>
        <table {...getTableProps()} className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50 sticky top-0 z-10'>
            {
                headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                scope='col'
                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? <ArrowSmDownIcon className='inline-block w-5 h-5'/> : <ArrowSmUpIcon className='inline-block w-5 h-5'/>) : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()} className='bg-white divide-y divide-gray-200'>
            {
                rows.map(row => {
                    prepareRow(row)
                    return ( <AssetTableRow key={row.original.uniqueKey()} row={row} catalogId={props.catalogId}/> )
                })
            }
            </tbody>
        </table>
        </div>
    )
}