import {Fragment} from 'react'
import { version } from '../lib/version'

import {Link, NavigateFunction, useNavigate} from 'react-router-dom'

import {Disclosure, Menu, Transition} from '@headlessui/react'

import {SearchIcon} from '@heroicons/react/solid'
import {MenuIcon, XIcon} from '@heroicons/react/outline'

import LocalUserInfo from '../types/LocalUserInfo'
import CurrentOrganization from '../types/CurrentOrganization'
import OrganizationSelect from './OrganizationSelect'
import {Auth} from '@aws-amplify/auth'

// TODO: Implement search (search both catalogs and assets at the same time)
// TODO: Release notes

const navigation = [
    { name: 'Catalogs', href: '/'},
    { name: 'Support', href: '/support'},
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function signOut(navigate:NavigateFunction) {
    Auth.signOut().then(() => {
        localStorage.removeItem('UserSession')
        localStorage.removeItem('UserOrganizations')
        localStorage.removeItem('UserInfo')
        sessionStorage.clear()

        navigate('/', {replace: true})
    })
}

export default function NavBar() {
    const navigate = useNavigate()
    const userInfo: LocalUserInfo = JSON.parse(localStorage.getItem('UserInfo') || '{}')
    const currentOrganization: CurrentOrganization = JSON.parse(sessionStorage.getItem('CurrentOrganization') || '{}')

    return (
        <Disclosure as='nav' className='bg-gray-800 z-50'>
            {({open}) => (
                <>
                    <div className='max-w mx-auto px-2 sm:px-4 lg:px-8'>
                        <div className='relative flex items-center justify-between h-16'>
                            <div className='flex items-center px-2 lg:px-0'>
                                <div className='flex-shrink-0'>
                                    <img
                                        className='block lg:hidden h-8 w-auto'
                                        src='/mini-logo.svg'
                                        alt='HeavyWorth'
                                    />
                                    <img
                                        className='hidden lg:block h-8 w-auto'
                                        src='/logo.svg'
                                        alt='HeavyWorth'
                                    />
                                </div>
                                <div className='hidden lg:block lg:ml-6'>
                                    <div className='flex space-x-4'>
                                        {/* Current: 'bg-gray-900 text-white', Default: 'text-gray-300 hover:bg-gray-700 hover:text-white' */}
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(
                                                    'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'px-3 py-2 rounded-md text-sm font-medium'
                                                )}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/*{(userInfo.admin || userInfo.multipleOrganizations) && <OrganizationSelect currentOrganization={currentOrganization}/>}*/}
                            <div className='flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end'>
                                <div className='max-w-lg w-full lg:max-w-xs'>
                                    <label htmlFor='search' className='sr-only'>
                                        Search
                                    </label>
                                    <div className='relative'>
                                        <div
                                            className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                            <SearchIcon className='h-5 w-5 text-gray-400' aria-hidden='true'/>
                                        </div>
                                        <input
                                            id='search'
                                            name='search'
                                            className='block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm'
                                            placeholder='Search'
                                            type='search'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex lg:hidden'>
                                {/* Mobile menu button */}
                                <Disclosure.Button
                                    className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                                    <span className='sr-only'>Open main menu</span>
                                    {open ? (
                                        <XIcon className='block h-6 w-6' aria-hidden='true'/>
                                    ) : (
                                        <MenuIcon className='block h-6 w-6' aria-hidden='true'/>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className='hidden lg:block lg:ml-4'>
                                <div className='flex items-center'>
                                    {/* Profile dropdown */}
                                    <Menu as='div' className='ml-4 relative flex-shrink-0'>
                                        <div>
                                            <Menu.Button
                                                className='bg-gray-800 flex text-sm text-white focus:outline-none'>
                                                <span className='sr-only'>Open user menu</span>
                                                <div>
                                                    <div className='text-sm'>
                                                        {userInfo.name}
                                                    </div>
                                                    <div className='text-xs text-gray-400'>
                                                        {userInfo.email}
                                                    </div>
                                                </div>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter='transition ease-out duration-100'
                                            enterFrom='transform opacity-0 scale-95'
                                            enterTo='transform opacity-100 scale-100'
                                            leave='transition ease-in duration-75'
                                            leaveFrom='transform opacity-100 scale-100'
                                            leaveTo='transform opacity-0 scale-95'
                                        >
                                            <Menu.Items
                                                className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                                {/*{userInfo.admin &&*/}
                                                {/*    <Menu.Item>*/}
                                                {/*        {({active}) => (*/}
                                                {/*            <Link*/}
                                                {/*                to='/admin'*/}
                                                {/*                className={classNames(*/}
                                                {/*                    active ? 'bg-gray-100' : '',*/}
                                                {/*                    'block px-4 py-2 text-sm text-gray-700'*/}
                                                {/*                )}*/}
                                                {/*            >*/}
                                                {/*                Admin*/}
                                                {/*            </Link>*/}
                                                {/*        )}*/}
                                                {/*    </Menu.Item>*/}
                                                {/*}*/}
                                                {/*<Menu.Item>*/}
                                                {/*    {({active}) => (*/}
                                                {/*        <Link*/}
                                                {/*            to='/settings'*/}
                                                {/*            className={classNames(*/}
                                                {/*                active ? 'bg-gray-100' : '',*/}
                                                {/*                'block px-4 py-2 text-sm text-gray-700'*/}
                                                {/*            )}*/}
                                                {/*        >*/}
                                                {/*            Settings*/}
                                                {/*        </Link>*/}
                                                {/*    )}*/}
                                                {/*</Menu.Item>*/}
                                                <Menu.Item>
                                                    <div className='block px-4 py-2 text-sm text-gray-700'>
                                                        App Version {version}
                                                    </div>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({active}) => (
                                                        <div className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700' )} onClick={() => signOut(navigate)}>
                                                            Sign out
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className='lg:hidden'>
                        <div className='px-2 pt-2 pb-3 space-y-1'>
                            {/* Current: 'bg-gray-900 text-white', Default: 'text-gray-300 hover:bg-gray-700 hover:text-white' */}
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classNames(
                                        'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block px-3 py-2 rounded-md text-base font-medium'
                                    )}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                        <div className='pt-4 pb-3 border-t border-gray-700'>
                            <div className='flex items-center px-5'>
                                <div>
                                    <div className='text-base font-medium text-white'>{userInfo.name}</div>
                                    <div className='text-sm font-medium text-gray-400'>{userInfo.email}</div>
                                </div>
                            </div>
                            <div className='flex items-center px-5'>
                                <div>
                                    <div className='text-base font-medium text-white'>Version {version}</div>
                                </div>
                            </div>
                            <div className='mt-3 px-2 space-y-1'>
                                {/*{userInfo.admin &&*/}
                                {/*    <Disclosure.Button*/}
                                {/*        as='a'*/}
                                {/*        href='/admin'*/}
                                {/*        className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'*/}
                                {/*    >*/}
                                {/*        Admin*/}
                                {/*    </Disclosure.Button>*/}
                                {/*}*/}
                                {/*<Disclosure.Button*/}
                                {/*    as='a'*/}
                                {/*    href='/settings'*/}
                                {/*    className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'*/}
                                {/*>*/}
                                {/*    Settings*/}
                                {/*</Disclosure.Button>*/}
                                <Disclosure.Button
                                    as='a'
                                    className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                                    onClick={() => signOut(navigate)}
                                >
                                    Sign out
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
