export enum ImageView {
    Profile = "AMKT",
    Additional = "BEXT",
    Odometer = "METER_MILES",
    OldOdometer = "GODO",
    HourMeter = "METER_HOUR",
    Acres = "METER_ACRES",
    SerialNumber = "ID_SERIAL",
    VIN = "ID_VIN",
    Sticker = "ID_STICKER",
    Meter = "HMET",
}

export function imageViewDisplayName(view: ImageView) {
    switch (view) {
        case ImageView.Profile: { return "Profile" }
        case ImageView.Additional: { return "Additional" }
        case ImageView.Odometer: { return "Odometer" }
        case ImageView.HourMeter: { return "Hour Meter" }
        case ImageView.Acres: { return "Acres" }
        case ImageView.Meter: { return "Meter" }
        case ImageView.SerialNumber: { return "Serial Number" }
        case ImageView.VIN: { return "VIN" }
        case ImageView.Sticker: { return "ICN/Sticker" }
        case ImageView.OldOdometer: { return "Odometer" }
    }
}