import axios, { AxiosResponse } from 'axios'
import {Auth} from "@aws-amplify/auth"

import { camelizeKeys } from 'humps'

import UserSession from "../types/UserSession"
import CurrentOrganization from "../types/CurrentOrganization"

const currentOrganization: CurrentOrganization = JSON.parse(sessionStorage.getItem('CurrentOrganization') || '{}')
const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        "Content-type": "application/json",
    },
})
api.interceptors.response.use((response: AxiosResponse) => {
    if (response.data) {
        response.data = camelizeKeys(response.data)
    }
    return response
})

async function getAuthToken() {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
}

const findAll = async () => {
    const response = await api.get<UserSession>("/v1/user_session", {
        headers: {
            "HW-Organization": currentOrganization.key,
            "Authorization": await getAuthToken(),
        }
    })

    return response.data
}

const UserSessionService = {
    findAll,
}

export default UserSessionService
