import {Route, Routes} from 'react-router-dom'

import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

import {CognitoUser} from '@aws-amplify/auth'

import NavBar from '../components/NavBar'

import OrganizationUsersService from '../services/OrganizationUsersService'

import CatalogList from './catalogs/CatalogList'
import Support from './Support'
import Admin from './Admin'
import Settings from './Settings'
import {useIntercom} from 'react-use-intercom'

import LocalUserInfo from '../types/LocalUserInfo'
import CurrentOrganization from '../types/CurrentOrganization'
import UserSessionService from '../services/UserSessionService'
import UserSession from '../types/UserSession'
import CatalogDetail from './catalogs/CatalogDetail'
import AssetDetail from './catalogs/assets/AssetDetail'
import ReleaseNotes from './ReleaseNotes'
import CreateCatalog from './catalogs/CreateCatalog'
import EditCatalog from './catalogs/EditCatalog'
import CreateAsset from "./catalogs/assets/CreateAsset";
import DetailsPlaceHolder from '../components/DetailsPlaceholder'
import EditPlaceHolder from '../components/EditPlaceholder'

const queryClient = new QueryClient()

interface AuthenticatedProps {
    authData: CognitoUser
}

function updateLocalUserInfo(payload: { [p: string]: any }): Boolean {
    let userInfo: LocalUserInfo = JSON.parse(localStorage.getItem('UserInfo') || '{}')
    if (userInfo != null && userInfo.key != null) return false

    userInfo.name = payload.name
    userInfo.email = payload.email
    userInfo.key = payload.sub
    userInfo.multipleOrganizations = (payload.multiple_organizations === 'true') || false
    userInfo.admin = payload['cognito:groups'].includes('PlatformAdmin')

    console.log('Setting user info = ', userInfo)
    localStorage.setItem('UserInfo', JSON.stringify(userInfo))

    return true
}

function updateCurrentOrganization(payload: { [p: string]: any }): Boolean {
    let currentOrganization: CurrentOrganization = JSON.parse(sessionStorage.getItem('CurrentOrganization') || '{}')
    if (currentOrganization != null && currentOrganization.name != null) return false

    currentOrganization.name = payload.organizations.split('|')[0]
    currentOrganization.key = payload.organization_keys.split('|')[0]

    console.log('Setting current organization', currentOrganization)
    sessionStorage.setItem('CurrentOrganization', JSON.stringify(currentOrganization))

    return true
}

async function updateCurrentOrganizationUsers() {
    const orgUsers = await OrganizationUsersService.findAll()
    sessionStorage.setItem('CurrentOrganizationUsers', JSON.stringify(orgUsers))
}

async function updateUserSession() {
    let savedUserSession: UserSession = JSON.parse(localStorage.getItem('UserSession') || '{}')
    if (savedUserSession != null && Date.now() - 5 * 60 * 1000 < savedUserSession.storedAt) return

    const userSession = await UserSessionService.findAll()
    userSession.storedAt = Date.now()
    console.log('Setting user session', userSession)
    localStorage.setItem('UserSession', JSON.stringify(userSession))

    const userOrganizations: CurrentOrganization[] = userSession.organizations.map((x) => {
        return {name: x.name, key: x.organizationId}
    }).sort((a, b) => a.name < b.name ? -1 : 1)
    console.log('Setting user organizations', userOrganizations)
    localStorage.setItem('UserOrganizations', JSON.stringify(userOrganizations))
}

export default function Authenticated(props: AuthenticatedProps) {
    const {update} = useIntercom()
    const signInUserSession = props.authData.getSignInUserSession()
    const payload = signInUserSession?.getIdToken().payload || {}

    if (updateLocalUserInfo(payload)) {
        console.log('Updating Intercom session')
        update({
            name: payload.name,
            email: payload.email,
            userId: payload.sub,
        })
    }

    updateCurrentOrganization(payload)

    updateUserSession().then(() => {
        // Notify of updated user session
    })

    updateCurrentOrganizationUsers().then(() => {
        // Notify of updated org users
    })

    return (
        <QueryClientProvider client={queryClient}>
            <div className='w-screen h-screen overflow-hidden flex flex-grow flex-col'>
                <NavBar/>
                <div className='h-full w-full'>
                    <ReactQueryDevtools initialIsOpen={false} position={'bottom-left'}/>
                    <Routes>
                        <Route path='/' element={<CatalogList/>} />
                        <Route path='catalogs/new' element={<CreateCatalog />} />
                        <Route path='catalogs/:catalogId' element={<CatalogDetail/>} />
                        <Route path='catalogs/:catalogId/edit' element={<EditCatalog/>} />
                        <Route path='catalogs/:catalogId/assets/new' element={<CreateAsset />} />
                        <Route path='catalogs/:catalogId/assets' element={<AssetDetail />}>
                            <Route path=':assetId' element={<DetailsPlaceHolder />} />
                            <Route path=':assetId/edit' element={<EditPlaceHolder />} />
                        </Route>
                        <Route path='catalogs' element={<CatalogList/>} />
                        <Route path='support' element={<Support/>}/>
                        <Route path='admin' element={<Admin/>}/>
                        <Route path='settings' element={<Settings/>}/>
                        <Route path='releases' element={<ReleaseNotes/>}/>
                    </Routes>
                </div>
            </div>
        </QueryClientProvider>
    )
}
