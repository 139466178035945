import Estimate from '../types/Estimate'
import EstimateType from '../types/EstimateType'
import {useState} from 'react'

interface UserEstimatesProps {
    assetKey: string,
    estimates: Estimate[],
    estimateTypes: EstimateType[],
    validTypes: string[]
}

export default function UserEstimates(props: UserEstimatesProps) {
    const [estimates, setEstimates] = useState(props.estimates)
    const dateFormat = (ts: string) => new Date(ts).toLocaleString()

    const displayNameForType = (estimateType: string) => {
        let rec = props.estimateTypes.find((et) => et.name === estimateType)
        if (!rec) {
            return estimateType
        }

        return rec.displayName
    }

    const estimateEntered = (estimateType: string, value: string) => {
        console.log(`${estimateType} -> ${value}`)
    }

    const estimateForType = (estimateType: string) => {
        return estimates.find((estimate) => estimate.estimateTypeName === estimateType)
    }

    return (
        <div>
            {props.validTypes.map((estimateType) => {
                const estimate = estimateForType(estimateType)

                return (
                    <div className='pb-1'>
                        <div>
                            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                {displayNameForType(estimateType)}
                            </label>
                            <div className="mt-1 relative rounded-md ">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">$</span>
                                </div>
                                <input
                                    type="number"
                                    name={estimateType}
                                    id={estimateType}
                                    className="focus:ring-blue-500 focus:border-blue-500 block w-40 pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={estimate ? estimate.value : ''}
                                    aria-describedby={`${estimateType}-currency`}
                                    onChange={(e) => estimateEntered(e.currentTarget.id, e.currentTarget.value)}
                                />
                                <div className="absolute inset-y-0 left-32 pr-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                    USD
                                </span>
                                </div>
                            </div>
                        </div>
                        {estimate && (
                            <div className='text-xs'>{dateFormat(estimate.modified)}</div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}