import {useNavigate} from 'react-router-dom'
import {FormEvent, useState} from 'react'

import AssetService from '../services/AssetService'

import Asset from '../types/Asset'
import Catalog from '../types/Catalog'
import FormError from '../types/FormError'
import UserSession from '../types/UserSession'
import Organization from '../types/Organization'

import CancelButton from './forms/CancelButton'
import SubmitButton from './forms/SubmitButton'
import SelectionField, {SelectionOption} from './forms/SelectionField'
import FormSection from './forms/FormSection'
import TextField from './forms/TextField'
import TextAreaField from './forms/TextAreaField'

export default function AssetForm(props: { catalog: Catalog | undefined, asset: Asset, backToCatalog: boolean }) {
    let userSession: UserSession = JSON.parse(localStorage.getItem('UserSession') || '{}')
    let currentOrganization: Organization = userSession.currentOrganization || {}
    let workflowConfig = currentOrganization.workflowConfig || {}

    const valuationTypes: SelectionOption[] = [
        {index: 0, label: 'List Asset/No Value', value: 'None',},
        {index: 1, label: 'Owner/Representative Value', value: 'Self',},
        {index: 2, label: 'Internal', value: 'Internal',},
        {index: 3, label: 'HeavyWorth Value', value: 'HeavyWorth',},
    ]
    const defaultValuationType = workflowConfig.defaultValuationType || 'HeavyWorth'

    // State
    const [asset, setAsset] = useState(props.asset)
    const assetChange = (key: string, value: any) => {
        let values: { [x: string]: any } = {}
        values[key] = value
        let newAsset = Object.assign(asset, values)
        setAsset(newAsset)

        let errors = asset.valid()
        setFormErrors(errors)
    }

    // Handling
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState(new Map<string, FormError>())
    const handleCancel = () => {
        if (props.catalog) {
            if (props.backToCatalog) {
                navigate(`/catalogs/${props.catalog.uniqueKey()}`)
            } else {
                navigate(`/catalogs/${props.catalog.uniqueKey()}/assets/${asset.uniqueKey()}`)
            }
        } else {
            navigate(`/catalogs`)
        }
    }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        console.log(asset)
        let errors = asset.valid()
        setFormErrors(errors)
        if (errors.size < 1) {
            // TODO: Handle errors
            saveAsset().then()
        } else {
            console.log(errors)
        }
        event.preventDefault()
    }
    const saveAsset = async () => {
        await AssetService.saveAsset(asset).then((updatedAsset) => {
            console.log(updatedAsset)
            // TODO: Update or expire cached asset
            if (props.catalog) {
                navigate(`/catalogs/${props.catalog.uniqueKey()}/assets/${asset.uniqueKey()}`)
            } else {
                navigate(`/catalogs`)
            }
        })
    }

    return (
        <form className='w-full h-full overflow-auto pb-64 space-y-8 divide-y divide-gray-200 p-4'
              onSubmit={handleSubmit}>
            <FormSection>
                <SelectionField id='valuationType'
                                label='Valuation Type'
                                options={valuationTypes}
                                value={asset.valuationType || defaultValuationType}
                                onChange={assetChange}/>
            </FormSection>

            <FormSection>
                <TextField id='year' label='Year' value={asset.year} onChange={assetChange}/>
                <TextField id='make' label='Make' value={asset.make} onChange={assetChange}/>
                <TextField id='model' label='Model' value={asset.model} onChange={assetChange}/>
                <TextField id='assetType' label='Asset Type' value={asset.assetType} onChange={assetChange}/>
            </FormSection>

            <FormSection>
                <TextField id='serial' label='Serial Number' value={asset.serial} onChange={assetChange}/>
                <TextField id='vin' label='VIN' value={asset.vin} onChange={assetChange}/>
                <TextField id='stickerId' label='Sticker' value={asset.stickerId} onChange={assetChange}/>
                <TextField id='inventoryTag' label='Inventory Tag' value={asset.inventoryTag}
                           onChange={assetChange}/>
                <TextField id='uniqueId' label='Unique ID #' value={asset.uniqueId} onChange={assetChange}/>
            </FormSection>

            <FormSection>
                {/*    TODO: Meters */}
            </FormSection>

            <FormSection>
                {/*    TODO: Features */}
                {/*    TODO: Problems */}
            </FormSection>

            <FormSection>
                <TextAreaField id='notes' label='Additional Notes' value={asset.notes} onChange={assetChange}/>
            </FormSection>

            <div className='pt-5 pr-20'>
                <div className='flex justify-end mb-2'>
                    {formErrors && formErrors.size > 0 && (
                        <div className='text-red-600 text-sm'>
                            Input errors have been found.
                        </div>)}
                </div>
                <div className='flex justify-end'>
                    <CancelButton title='Cancel' onCancel={handleCancel}/>
                    <SubmitButton title='Save'/>
                </div>
            </div>
        </form>)
}