import {useState} from "react";

export interface LabelSelectionOption {
    index: number,
    label: string,
    value: string,
}

interface LabelSelectionFieldProps {
    id:string,
    label:string,
    options:LabelSelectionOption[],
    values:string[],
    onChange: (key:string, values:any) => void
}

export default function LabelSelectionField(props: LabelSelectionFieldProps) {
    const [values, setValues] = useState(props.values)

    // Control checkboxes
    let initialChecks = props.options.map((x, _idx) => values.includes(x.value))
    const [checkedState, setCheckedState] = useState(initialChecks)

    // Handle changes
    const updateCheckedState = (position:number) => {
        const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item)
        setCheckedState(updatedCheckedState)
    }
    const updateValues = (checked:boolean, value:string) => {
        let newValues = values
        let idx = newValues.findIndex(x => x === value)
        if (checked) {
            if (idx < 0) {
                newValues.push(value)
            }
        } else {
            if (idx >= 0) {
                newValues.splice(idx, 1)
            }
        }
        setValues(newValues.sort())
    }
    const changeValue = (position:number, checked:boolean, value:string) => {
        updateCheckedState(position)
        updateValues(checked, value)
        props.onChange(props.id, values)
    }

    return (
        <div
            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={props.id}
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {props.label}
            </label>
            <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                {props.options.map((option:LabelSelectionOption, optionIndex) => (
                    <div key={optionIndex} className="relative flex items-start py-4">
                        <div className="mr-3 flex items-center h-5">
                            <input
                                id={`option${option.index}`}
                                type="checkbox"
                                checked={checkedState[optionIndex]}
                                onChange={e => changeValue(option.index, e.target.checked, option.value) }
                                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                        </div>
                        <div className="min-w-0 flex-1 text-sm">
                            <label htmlFor={`option-${option.index}`} className="font-medium text-gray-700 select-none">
                                {option.label}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}