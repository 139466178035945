import {Row} from 'react-table'
import {useNavigate} from 'react-router-dom'

import Asset from '../types/Asset'

interface AssetTableRowProps {
    row: Row<Asset>,
    catalogId: string,
}

export default function AssetTableRow(props: AssetTableRowProps) {
    const navigate = useNavigate()
    const {key, ...rowProps} = props.row.getRowProps()
    const assetId = props.row.original.uniqueKey()

    return (
        <tr {...rowProps} onClick={() => {navigate(`/catalogs/${props.catalogId}/assets/${assetId}`)}}>
            {props.row.cells.map(cell => {
                return (
                    <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                    </td>
                )
            })}
        </tr>
    )
}