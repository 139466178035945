import {useState} from "react";

export interface SelectionOption {
    index: number,
    label: string,
    value: string,
}

interface SelectionFieldProps {
    id: string,
    label: string,
    options: SelectionOption[],
    value: string,
    onChange: (key: string, values: any) => void
}

export default function SelectionField(props: SelectionFieldProps) {
    const initialValue = props.options.find((x) => x.value === props.value) || props.options[props.options.length - 1]
    const [selected, setSelected] = useState(initialValue)
    const selectOption = (value: string) => {
        let option = props.options.find(x => x.value === value) || props.options[0]
        setSelected(option)
        props.onChange(props.id, value)
    }

    return (
        <div>
            <label htmlFor={props.id}
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {props.label}
            </label>
            <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                defaultValue={selected.value}
                onChange={(e) => {
                    selectOption(e.target.value)
                }}>
                {props.options.map(x => (<option value={x.value}>{x.label}</option>))}
            </select>
        </div>
    )
}