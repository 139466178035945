import {useState} from 'react'
import {ImageView, imageViewDisplayName} from '../types/ImageView'


interface ImageViewSelectProps {
    currentValue: ImageView,
    onChange: (value: ImageView) => void,
}

export default function ImageViewSelect(props: ImageViewSelectProps) {
    const options = [ImageView.Profile, ImageView.Additional, ImageView.Odometer,
        ImageView.HourMeter, ImageView.Acres, ImageView.SerialNumber,
        ImageView.VIN, ImageView.Sticker]

    let currentStatusIndex = options.findIndex(x => x === props.currentValue)
    const [selected, setSelected] = useState(currentStatusIndex < 0 ? ImageView.Additional : options[currentStatusIndex])
    const selectOption = (value: string) => {
        let option = options.find(x => x === value) || options[1]
        setSelected(option)
        props.onChange(option)
    }

    return (
        <select
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue={selected}
            onChange={(e) => {
                selectOption(e.target.value)
            }}>
            {options.map(x => (<option key={x} value={x}>{imageViewDisplayName(x)}</option>))}
        </select>
    )
}