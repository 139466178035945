import {useState} from "react";

interface EmailFieldProps {
    id: string,
    label: string,
    value: string|undefined,
    onChange: (key:string, value:any) => void
}
export default function EmailField(props: EmailFieldProps) {
    const [value, setValue] = useState(props.value)
    const changeValue = (v:string) => {
        setValue(v);
        props.onChange(props.id, v)
    }

    return (
        <div
            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={props.id}
                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {props.label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                    type="email"
                    name={props.id}
                    id={props.id}
                    value={value}
                    onChange={e => changeValue(e.target.value) }
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
            </div>
        </div>
    )
}