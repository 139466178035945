import {useMemo} from 'react'
import {Column} from 'react-table'
import {useQuery} from 'react-query'
import {Link, useParams} from 'react-router-dom'

import CatalogService from '../../services/CatalogService'
import Catalog from '../../types/Catalog'
import Asset from '../../types/Asset'

import StringCell from '../../components/cells/StringCell'
import DateCell from '../../components/cells/DateCell'
import ProfileImageCell from '../../components/cells/ProfileImageCell'
import AssetTable from '../../components/AssetTable'
import Breadcrumbs from '../../components/Breadcrumbs'
import CatalogContacts from '../../components/CatalogContacts'
import WorkflowStatus, {WorkflowStatusOption} from '../../components/WorkflowStatus'

// TODO: Deep-link to catalog
export default function CatalogDetail() {
    const params = useParams()
    const catalogId = params.catalogId || ''   // TODO[Error]: Handle null or bad param
    const editUrl = `/catalogs/${catalogId}/edit`

    // TODO[Feature]: Reorder columns
    // TODO[Feature]: Hide columns
    const columns = useMemo<Column<Asset>[]>(
        () => [
            {Header: 'Profile', id: 'profileImage', accessor: 'imageCount', Cell: ProfileImageCell},
            {
                Header: 'Valuation Type',
                id: 'valuationType',
                accessor: 'valuationType',
                Cell: StringCell
            },
            {Header: 'Year', id: 'year', accessor: 'year', Cell: StringCell},
            {Header: 'Make', id: 'make', accessor: 'make', Cell: StringCell},
            {Header: 'Model', id: 'model', accessor: 'model', Cell: StringCell},
            {Header: 'Asset Type', id: 'assetType', accessor: 'assetType', Cell: StringCell},
            {Header: 'Serial', id: 'serial', accessor: 'serial', Cell: StringCell},
            {Header: 'VIN', id: 'vin', accessor: 'vin', Cell: StringCell},
            {Header: 'Unique ID #', id: 'uniqueId', accessor: 'uniqueId', Cell: StringCell},
            {Header: 'Sticker', id: 'sticker', accessor: 'stickerId', Cell: StringCell},
            {
                Header: 'Inventory Tag',
                id: 'inventoryTag',
                accessor: 'inventoryTag',
                Cell: StringCell
            },
            {Header: 'Location', id: 'location', accessor: 'location', Cell: StringCell},
            {
                Header: 'Created',
                id: 'created',
                accessor: 'created',
                Cell: DateCell
            },
            {
                Header: 'Updated', id: 'updated',
                accessor: (asset => {
                    return asset.updated ? asset.updated : asset.created
                }),
                Cell: DateCell
            },
        ],
        []
    )

    const {isLoading, isError, data, error} = useQuery<Catalog, Error>(
        ['catalog', catalogId],
        async () => {
            return await CatalogService.findById(catalogId)
        }
    )

    const newAssetPath = `/catalogs/${catalogId}/assets/new`

    const pages = [
        {name: data ? data.name : 'Catalog', href: `/catalogs/${catalogId}`, current: true},
    ]

    // TODO[Branding]: Move these values into the branding
    const workflowOptions:WorkflowStatusOption[] = [
        {value: 'capture_in_progress', name: 'Capture In Progress'},
        {value: 'eval_requested', name: 'Valuation Requested'},
        {value: 'eval_in_progress', name: 'Valuation In Progress'},
        {value: 'final_review', name: 'Final Review'},
        {value: 'finalized', name: 'Finalized'},
        {value: 'archived', name: 'Archived'},
    ]
    const changeStatus = (value: string) => {
        if (data) {
            data.workflowStatus = value
            CatalogService.changeState(data).then()
        }
    }

    return (
        <div className='flex flex-grow flex-col'>
            <div className='hidden md:block'>
                <Breadcrumbs pages={pages}/>
            </div>
            {isLoading && (<div className='text-gray-600'>
                <div className='inline-block animate-ping h-4 w-4 rounded-full bg-gray-600 opacity-75 my-auto'/>
                <span className='mx-6 my-auto'>Loading catalog details...</span>
            </div>)}
            {isError && (<div className='bg-red-500'>
                Error....{error}
            </div>)}
            {!isLoading && !isError && data && (
                <div>
                    <div className='w-screen py-4 px-4'>
                        <div className='w-full flex flex-col lg:flex-row gap-4 lg:gap-4'>
                            <div className='w-full flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4'>
                                <div>
                                    <div className='text-xs text-gray-400'>Catalog Details</div>
                                    <h1 className='text-lg leading-6 font-medium text-gray-900'>{data.name}</h1>
                                    <h5 className='text-xs'>{data.email}</h5>
                                </div>
                                <CatalogContacts primaryContactName={data.primaryContactName}
                                                 primaryContactId={data.primaryContactId}
                                                 subscriptions={data.subscriptions}/>
                                <div>
                                    <div className='text-xs text-gray-400'>Workflow Status</div>
                                    <WorkflowStatus options={workflowOptions} currentValue={data.workflowStatus} onChange={changeStatus} />
                                </div>
                                <div>
                                    <div className='text-xs text-gray-400'>Catalog Actions</div>
                                    <div className='flex grid grid-cols-3 sm:grid-cols-2 xl:grid-cols-3 gap-4 '>
                                        <div className='w-24'>
                                            <Link to={newAssetPath}
                                                  className='flex p-1 item-center justify-center text-xs  rounded text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'>
                                                New Asset
                                            </Link>
                                        </div>
                                        {/* TODO: Export catalog */}
                                        <div className='w-24'><a href='#'
                                                                 className='flex p-1 item-center justify-center text-xs  rounded text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'>Export</a>
                                        </div>
                                        {/*/!* TODO: Open catalog in new tab *!/*/}
                                        {/*<div className='w-24'><a href='#'*/}
                                        {/*                         className='flex p-1 item-center justify-center text-xs  rounded text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'*/}
                                        {/*                         target='_blank'>New Tab</a></div>*/}
                                        {/*/!* TODO[Feature]: Catalog history *!/*/}
                                        {/*<div className='w-24'><a href='#'*/}
                                        {/*                         className='flex p-1 item-center justify-center text-xs rounded text-white bg-blue-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'*/}
                                        {/*                         target='_blank'>History</a></div>*/}
                                        <div className='w-24'>
                                            <Link to={editUrl}
                                                  className='flex p-1 item-center justify-center text-xs rounded text-white bg-orange-600 bg-opacity-60 hover:bg-opacity-70 shadow-sm'>
                                                Edit
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <main className='h-screen w-screen overflow-hidden px-4 py-4'>
                        {/* TODO: Display value columns */}
                        {/* TODO: Pin columns */}
                        {/* TODO: Value asset from asset table */}
                        <AssetTable columns={columns} data={data ? data.assets : []} catalogId={catalogId}/>
                    </main>
                </div>
            )}
        </div>
    )
}