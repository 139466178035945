import {useParams, Outlet, useOutletContext} from 'react-router-dom'
import {useQuery} from 'react-query'

import Asset from '../../../types/Asset'
import Catalog from '../../../types/Catalog'

import AssetService from '../../../services/AssetService'

import Breadcrumbs from '../../../components/Breadcrumbs'
import ImageDisplay from '../../../components/ImageDisplay'
import ImageService from '../../../services/ImageService'
import Image from '../../../types/Image'
import {ImageView} from '../../../types/ImageView'
import {useState} from 'react'
import AssetValuation from '../../../components/AssetValuation'

type AssetDetailContextType = { catalog: Catalog | undefined, asset: Asset };

// TODO: Deep-link to asset
// TODO: Display valuation form
export default function AssetDetail() {
    const params = useParams()
    const catalogId = params.catalogId || ''   // TODO[Error]: Handle null or bad param
    const assetId = params.assetId || ''   // TODO[Error]: Handle null or bad param

    const {isLoading, isError, data, error} = useQuery<Asset, Error>(
        ['asset', assetId],
        async () => {
            let a = await AssetService.findById(assetId)
            setAsset(a)
            setImages(a.images)
            return a
        },
        {
            staleTime: 5 * 60 * 1000
        }
    )
    const [asset, setAsset] = useState(data)

    const pages = [
        {
            name: (asset && asset.catalog) ? (asset.catalog.name || 'Catalog') : 'Catalog',
            href: `/catalogs/${catalogId}`,
            current: false
        },
        {
            name: asset ? asset.breadcrumbDescription() : `Asset ${assetId}`,
            href: `/catalogs/${catalogId}/assets/${assetId}`,
            current: true
        },
    ]

    const rotateImage = async (image: Image, rotation: number) => {
        console.log("Rotate Image")
        await ImageService.rotateImage(image.imageId, rotation).then((image) => {
            if (!images) { return }

            let index = images.findIndex((x) => x.imageId === image.imageId)
            if (index >= 0) {
                images[index] = image
                setImages([...images])
            }
        })
    }

    const deleteImage = async (image: Image) => {
        if (!data || !images) {
            return
        }

        let index = images.findIndex((x) => x.imageId === image.imageId)
        if (index >= 0) {
            images[index].deleted = true
            setImages([...images])
        }

        console.log(`Delete Image: ${image.imageId}`)
        await ImageService.deleteImage(image.itemId, image.imageId).then(() => {
        })
    }

    const recategorizeImage = async (image: Image, newView: ImageView) => {
        console.log(`Recategorize image to ${newView}`)
        await ImageService.setImageView(image.itemId, image.imageId, newView).then(() => {
        })
    }

    const [images, setImages] = useState(asset?.images)
    const fullImages = () => {
        return images?.filter((image): image is Image => !image.deleted).map((image) =>
            <ImageDisplay key={image.imageId} image={image} delete={deleteImage}
                          rotate={rotateImage} recategorize={recategorizeImage}/>)
    }

    return (
        <div className='flex flex-grow flex-col'>
            <div className='block'>
                <Breadcrumbs pages={pages}/>
            </div>
            <main className='h-screen w-screen overflow-hidden flex flex-col'>
                <div className='flex-grow w-full mx-auto lg:flex overflow-auto lg:overflow-hidden'>
                    {/* Left sidebar */}
                    <div className=':flex-shrink-0 lg:border-2 lg:border-gray-100 lg:overflow-y-auto'>
                        <div className='h-full p-4 lg:w-96 lg:-mb-96 lg:overflow-y-auto'>
                            <Outlet context={{catalog: data && data.catalog ? data.catalog : null, asset: data}}/>
                        </div>
                    </div>
                    {/* Images */}
                    <div className='flex-1 min-w-0 bg-gray-100 xl:flex lg:overflow-y-auto flex-col'>
                        <div className='flex-1 min-w-0 xl:flex lg:overflow-y-auto flex-col'>
                            <div className='pb-96'>
                                {fullImages()}
                            </div>
                        </div>
                    </div>
                    {/* Valuation */}
                    <div className='pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-2 lg:border-gray-100 xl:pr-0'>
                        <div className='h-full p-4 lg:w-96 lg:-mb-96 lg:overflow-y-auto'>
                            {data && (<AssetValuation asset={data} />)}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export function useAssetContext() {
    return useOutletContext<AssetDetailContextType>();
}