import {useMemo} from 'react'
import {Column} from 'react-table'
import {useQuery} from 'react-query'

import CatalogService from '../../services/CatalogService'

import Catalog from '../../types/Catalog'

import CatalogTable from '../../components/CatalogTable'
import DateCell from '../../components/cells/DateCell'
import StatusCell from '../../components/cells/StatusCell'
import NumberCell from '../../components/cells/NumberCell'
import StringCell from '../../components/cells/StringCell'

export default function CatalogList() {
    const columns = useMemo<Column<Catalog>[]>(
        () => [
            {Header: 'Primary Contact', id: 'primaryContactName', accessor: 'primaryContactName', Cell: StringCell, sortType: 'alphanumeric'},
            {Header: 'Asset Owner', id: 'name', accessor: 'name', Cell: StringCell, sortType: 'alphanumeric'},
            {Header: 'Assets', id: 'itemCount', accessor: 'itemCount', Cell: NumberCell, sortType: 'basic'},
            {Header: 'Photos', id: 'imageCount', accessor: 'imageCount', Cell: NumberCell, sortType: 'basic'},
            {Header: 'Created', id: 'created', accessor: 'created', Cell: DateCell, sortType: 'basic'},
            {Header: 'Updated', id: 'updated', accessor: 'updated', Cell: DateCell, sortType: 'basic'},
            {Header: 'Status', id: 'workflowStatus', accessor: 'workflowStatus', Cell: StatusCell},
        ],
        []
    )

    const {isLoading, isError, data, error} = useQuery<Catalog[], Error>(
        'query-open-catalogs',
        async () => {
            return await CatalogService.findAll()
        }
    )

    return (
        <div className='flex flex-grow flex-col'>
            <div className='w-full py-4 px-4'>
                <div className='w-32'>
                    <a href='/catalogs/new'
                       className='flex p-1 item-center justify-center font-medium rounded shadow-sm text-white bg-blue-800 bg-opacity-60 hover:bg-opacity-70'>New Catalog</a>
                </div>

                {/* TODO: Filter catalog table on status*/}
            </div>
            <main className='h-full w-full overflow-hidden px-4 py-4'>
                {isLoading && (<div className='text-gray-600'>
                    <div className='inline-block animate-ping h-4 w-4 rounded-full bg-gray-600 opacity-75 my-auto'/>
                    <span className='mx-6 my-auto'>Loading catalog list...</span>
                </div>)}
                {isError && (<div className='bg-red-500'>
                    Error....{error}
                </div>)}
                {!isLoading && !isError && (<CatalogTable columns={columns} data={data || []}/>)}
            </main>
        </div>
    )
}