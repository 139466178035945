interface CatalogStatus {
    name: string,
    color: string,
}

const statuses:{[key: string]:CatalogStatus} = {
    capture_in_progress: {
        name: 'Capture In Progress',
        color: 'green-400',
    },
    eval_requested: {
        name: 'Valuation Requested',
        color: 'blue-400',
    },
    eval_in_progress: {
        name: 'Valuation In Progress',
        color: 'yellow-400',
    },
    in_review: {
        name: 'In Review',
        color: 'yellow-500',
    },
    finalized: {
        name: 'Finalized',
        color: 'blue-700',
    },
    archived: {
        name: 'Archived',
        color: 'black',
    },
}

export default function StatusCell(props:any) {
    const status = statuses[props.cell.value] || statuses['capture_in_progress']
    return (
        <div className="mx-2">
            {status.name}
        </div>
    )
}