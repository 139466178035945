import {useState} from 'react'

import Image from '../types/Image'
import {XCircleIcon} from '@heroicons/react/solid'

interface ImageDetailsProps {
    image: Image,
    close: () => void
}

export default function ImageDetails(props: ImageDetailsProps) {
    const [image, setImage] = useState(props.image)

    const created = new Date(image.created)
    const updated = new Date(image.updated)

    return (
        <div className="md:m-16 bg-white rounded shadow-2xl">
            <div className="w-full md:w-32 float-right p-4 rounded bg-gray-100"
                 onClick={(e) => props.close()}>
                <XCircleIcon className="inline flex-shrink-0 h-5 w-5" />
                <span className="pl-2">Close</span>
            </div>

            <ul className="clear-both md:clear-none p-8 list-disc list-inside">
                <li>
                    <label className="font-bold">Image Key:</label> {image.imageId}
                </li>
                <li>
                    <label className="font-bold">Latitude/Longitude:</label> {image.latitude}, {image.longitude}
                </li>
                <li>
                    <label className="font-bold">Resolution:</label> {image.originalX} x {image.originalY}
                </li>
                <li>
                    <label className="font-bold">File Size:</label> {image.fileSize} bytes
                </li>
                <li>
                    <label className="font-bold">Created:</label> {created.toLocaleString()}
                </li>
                <li>
                    <label className="font-bold">Updated:</label> {updated.toLocaleString()}
                </li>
                {image.sizes && (
                    <li>
                        <label className="font-bold">Processed Image Sizes:</label>
                        <ul className="list-inside list-decimal indent-8">
                            {image.sizes.original && (
                                <li><a className="underline decoration-blue-600" href={image.sizes.original}
                                       target="_blank">Original</a></li>)}
                            {image.sizes.reduced && (
                                <li><a className="underline decoration-blue-600" href={image.sizes.reduced}
                                       target="_blank">Reduced</a></li>)}
                            {image.sizes.web && (<li><a className="underline decoration-blue-600" href={image.sizes.web}
                                                        target="_blank">Web</a></li>)}
                            {image.sizes.thumbnail && (
                                <li><a className="underline decoration-blue-600" href={image.sizes.thumbnail}
                                       target="_blank">Thumbnail</a></li>)}
                        </ul>
                    </li>
                )}
            </ul>
        </div>
    )
}