import Estimate from '../types/Estimate'
import EstimateType from '../types/EstimateType'

export default function OtherEstimates(props: { estimateTypes: EstimateType[], validTypes: string[], estimates: Estimate[] }) {
    const singleType = props.validTypes.length === 1
    const numberFormat = (value: number) => new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD'
    }).format(value)
    const dateFormat = (ts: string) => new Date(ts).toLocaleString()
    const users = Array.from(new Set(props.estimates.map((estimate) => {
        return {name: estimate.userName, key: estimate.userUuid}
    })))

    const estimatesForUser = (ests: Estimate[], userKey: string) => {
        return ests.filter((estimate) => estimate.userUuid === userKey)
    }
    const estimatesForType = (ests: Estimate[], estimateType: string) => {
        return ests.filter((estimate) => estimate.estimateTypeName === estimateType)
    }
    const displayNameForType = (estimateType: string) => {
        let rec = props.estimateTypes.find((et) => et.name === estimateType)
        if (!rec) {
            return estimateType
        }

        return rec.displayName
    }

    if (props.estimates.length < 1) {
        return (
            <div className='pt-2'>
                No other estimates have been saved.
            </div>
        )
    }

    return (
        <div className='pt-2'>
            {/*Single Value Type*/}
            {singleType && props.estimates.map((estimate) => {
                    return (
                        <div className='pb-1'>
                            <div>
                                <label>{estimate.userName}</label>
                                <span className='float-right'>{numberFormat(estimate.value)}</span>
                            </div>
                            <p className='text-xs'>{dateFormat(estimate.modified)}</p>
                        </div>
                    )
                }
            )}

            {/*Multiple Value Types*/}
            {!singleType && users.map((user) => {
                    const estVales = props.validTypes.map((et) => {
                        const displayName = displayNameForType(et)
                        return estimatesForType(estimatesForUser(props.estimates, user.key), et).map((estimate) => (
                            <div className='pl-6'>
                                <label>{displayName}</label>
                                <span className='float-right'>{numberFormat(estimate.value)}</span>
                                <p className='text-xs'>{dateFormat(estimate.modified)}</p>
                            </div>
                        ))
                    })

                    return (
                        <div className='pb-1'>
                            <span className='font-medium'>{user.name}</span>
                            {estVales}
                        </div>
                    )
                }
            )}
        </div>
    )
}