import {Row} from 'react-table'
import Catalog from '../types/Catalog'
import {useNavigate} from 'react-router-dom'

interface CatalogTableRowProps {
    row: Row<Catalog>
}

export default function CatalogTableRow(props: CatalogTableRowProps) {
    const navigate = useNavigate()
    const {key, ...rowProps} = props.row.getRowProps()
    const catalogId = props.row.original.uniqueKey()

    return (
        <tr {...rowProps} onClick={() => {navigate(`/catalogs/${catalogId}`)}}>
            {props.row.cells.map(cell => {
                return (
                    <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                    </td>
                )
            })}
        </tr>
    )
}